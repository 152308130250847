const App=()=>{
  return  <div className="container">
            <div className="row">
              <div className="col">
                Default
              </div>
            </div>
          </div>
}

export default  App
