const exportar  = {
  Prefijo:"/pwa",
  ConfigAppUrl : (document.location.hostname==='localhost')?window.location.origin+'/': (process.env.NODE_ENV==='development')?'https://pwa.ingeeniar.com/':'https://pwa.ingeeniar.com/',
  ConfigSocketUrl : (document.location.hostname==='localhost')?'https://socket.programandoweb.net:10081/':(process.env.NODE_ENV==='development')?'https://socket.programandoweb.net:10081/':'https://socket.programandoweb.net:10081/',
  ConfigNotifications:process.env.REACT_APP_URL_NOTIFICATIONS,
  ConfigApirest   : (document.location.hostname==='localhost')?'http://localhost/ingeproyectos/ApiRest/':(process.env.NODE_ENV==='development')?'https://pwa.ingeeniar.com/backend/apirest/':'https://pwa.ingeeniar.com/backend/apirest/',
  //ConfigApirest   : (document.location.hostname==='localhost')?'https://trymoud.com/apirest/':(process.env.NODE_ENV==='development')?'https://trymoud.com/apirest/':'https://trymoud.com/apirest/',
  Title:'IngeProyectos',
  Alt:'IngeProyectos',
  db:"IngeProyectos",
}

export default exportar
